@font-face {
  font-family: Pixeled;
  src: url("Pixeled.8a591232.ttf");
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
}

h1, input {
  color: #010101;
  user-select: none;
  touch-action: manipulation;
  margin: 20px;
  font-family: Pixeled;
  font-size: 35px;
}

h2 {
  user-select: none;
  touch-action: manipulation;
  font-size: 160px;
}

div {
  touch-action: manipulation;
}

input {
  border: 5px solid #000;
}

input:focus {
  border: 5px solid #2060d6;
  outline: none;
}

#concept-input {
  width: 80%;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}

#wow-button-1, #wow-button-2, #wow-button-3, #wow-button-4, #wow-button-5, #wow-button-6, #vote-button-1, #vote-button-2, #vote-button-3, #vote-button-4 {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

#vote-button-1, #vote-button-2, #vote-button-3, #vote-button-4 {
  box-sizing: border-box;
  border: 2px solid #fff;
}

#wow-button-1, #wow-button-2, #wow-button-3, #wow-button-4, #wow-button-5, #wow-button-6 {
  text-shadow: 0 0 2px #000;
}

#vote-button-1.two-vote {
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
}

#vote-button-2.two-vote {
  border-bottom: hidden;
  border-left: hidden;
  border-right: hidden;
}

#vote-button-1:not(.two-vote) {
  border-top: hidden;
  border-left: hidden;
}

#vote-button-2:not(.two-vote) {
  border-top: hidden;
  border-right: hidden;
}

#vote-button-3 {
  border-bottom: hidden;
  border-left: hidden;
}

#vote-button-4 {
  border-bottom: hidden;
  border-right: hidden;
}

#main-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(40deg, #e037378e, #195ce88e);
}

#react-container {
  width: 100%;
  height: 100%;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  display: none;
}

#vote-container {
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  display: none;
}

#concept-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  place-content: center;
  align-items: center;
  display: none;
}

#reminder-top, #reminder-bot {
  color: #010101;
  user-select: none;
  touch-action: manipulation;
  pointer-events: none;
  justify-self: center;
  font-family: Pixeled;
  font-size: 21px;
  position: absolute;
}

#reminder-top {
  margin-top: 50px;
}

#reminder-bot {
  align-self: end;
  margin-bottom: 50px;
}

/*# sourceMappingURL=index.60cbc0cd.css.map */
