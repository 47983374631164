@font-face {
    font-family: Pixeled;
    src: url(Pixeled.ttf);
}

body, 
html {
    width: 100%;
    height: 100%;
    margin: 0;
}

h1, input {
    color: #010101;
    font-size: 35px;
    margin: 20px;
    font-family: Pixeled;
    user-select: none;
    touch-action: manipulation;
}

h2 {
    font-size: 160px;
    user-select: none;
    touch-action: manipulation;
}

div {
    touch-action: manipulation;
}

input {
    border: 5px solid black;
}

input:focus {
    border: 5px solid #2060d6;
    outline: none;
}

#concept-input {
    width: 80%;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
}

#wow-button-1, #wow-button-2, #wow-button-3, #wow-button-4, #wow-button-5, #wow-button-6, 
#vote-button-1, #vote-button-2, #vote-button-3, #vote-button-4 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#vote-button-1, #vote-button-2, #vote-button-3, #vote-button-4 {
    border: 2px solid white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;         
}

#wow-button-1, #wow-button-2, #wow-button-3, #wow-button-4, #wow-button-5, #wow-button-6 {
    text-shadow: 0 0 2px black;
}

#vote-button-1.two-vote {
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
}
#vote-button-2.two-vote {
    border-bottom: hidden;
    border-left: hidden;
    border-right: hidden;
}
#vote-button-1:not(.two-vote) {
    border-top: hidden;
    border-left: hidden;
}
#vote-button-2:not(.two-vote) {
    border-top: hidden;
    border-right: hidden;
}
#vote-button-3 {
    border-bottom: hidden;
    border-left: hidden;
}
#vote-button-4 {
    border-bottom: hidden;
    border-right: hidden;
}

#main-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(40deg, #e037378e, #195CE88e)
}

#react-container {
    width: 100%;
    height: 100%;
    display: none;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}

#vote-container {
    width: 100%;
    height: 100%;
    display: none;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

#concept-container {
    width: 100%;
    height: 100%;
    display: none;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#reminder-top, #reminder-bot {
    position: absolute;
    justify-self: center;
    color: #010101;
    font-size: 21px;
    font-family: Pixeled;
    user-select: none;
    touch-action: manipulation;
    pointer-events: none;
}

#reminder-top {
    margin-top: 50px;
}

#reminder-bot {
    margin-bottom: 50px;
    align-self: end;
}